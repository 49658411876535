export default {
  "word": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
    "man": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
    "woman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "password_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワード"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード確認"])},
    "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードリセット"])},
    "password_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
    "game_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム履歴"])},
    "self_pr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己PR"])},
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイコン画像"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])}
  },
  "validation": {
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません。"])}
  },
  "error": {
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインして下さい。"])},
    "413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードサイズが大きすぎます。"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーでエラーが発生しました。しばらくしてからもう一度お試しください。"])}
  },
  "register": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録を完了するためのメールを送信しました。届いたメールに記載のリンクをクリックして、登録を完了してください。"])}
  },
  "reset_password": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットしました。"])}
  },
  "forgot_password": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れたときは？"])},
    "send_link_messeage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをリセットするためのリンクを送信します。登録したメールアドレスを入力してください。"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再設定するためのメールを送信しました。届いたメールに記載のリンクをクリックして、パスワードの再設定画面を開いてください。"])}
  },
  "teaser": {
    "title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the largest game guild"])},
    "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is just launched!"])},
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WizPlayee is a game guild that you can play and earn."])},
    "about_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our game guild is for collecting and earning players through the blockchain ecosystem and we also are the pioneers of the new world of Metaverse!"])},
    "about_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a total of ●● NFT decks, including over ●● Legendary Cards."])}
  }
}