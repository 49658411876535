import { RouteRecordRaw } from 'vue-router'

import Teaser from '@/pages/Teaser.vue'
// import Login from '@/pages/Login.vue'
// import Register from '@/pages/Register.vue'
// import ActivateExecute from '@/pages/ActivateExecute.vue'
// import Home from '@/pages/Home.vue'
// import Account from '@/pages/Account.vue'
// import ForgotPassword from '@/pages/ForgotPassword.vue'
// import ResetPassword from '@/pages/ResetPassword.vue'
// import SocialLoginCallback from '@/pages/SocialLoginCallback.vue'
// import NftSetList from '@/pages/NftSetList.vue'
// import NftSetDetails from '@/pages/NftSetDetails.vue'
// import NotFound from '@/pages/NotFound.vue'

export const childrenRoutes: RouteRecordRaw[] = [
  { path: '', name: 'Teaser', component: Teaser },
  // { path: 'login', name: 'Login', component: Login },
  // { path: 'login/:provider/callback', component: SocialLoginCallback },
  // { path: 'register', component: Register },
  // { path: 'forgot-password', component: ForgotPassword },
  // { path: 'reset-password/:token', component: ResetPassword },
  // { path: 'activate/execute/:email/:code', component: ActivateExecute },
  // { path: 'home', component: Home },
  // { path: 'account', component: Account },
  // { path: 'nft-set', component: NftSetList },
  // { path: 'nft-set/:nftSetId', component: NftSetDetails },
  // { path: ':pathMatch(.*)*', name: 'NotFound', component: NotFound },
]
