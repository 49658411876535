import { createRouter, createWebHistory, RouteRecordRaw, NavigationGuardWithThis } from 'vue-router'
import i18n from '../plugins/i18n'
import { childrenRoutes } from './children-routes'

import Locale from '@/pages/Locale.vue'

const setI18nLocale: NavigationGuardWithThis<undefined> | NavigationGuardWithThis<undefined>[] = (to, from, next) => {
  const toLocale = typeof to.params.locale === 'string' ? to.params.locale : process.env.VUE_APP_I18N_LOCALE
  const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split('|')
  if (supportedLocales.includes(toLocale)) {
    if (i18n.global.locale.value !== toLocale) {
      i18n.global.locale.value = toLocale
    }
  }
  return next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: `/:locale(${process.env.VUE_APP_I18N_SUPPORTED_LOCALE})?`,
    beforeEnter: setI18nLocale,
    component: Locale,
    children: childrenRoutes
  }
]

const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
}

const router = createRouter(routerOptions)

export default router
