import { MutationTree } from 'vuex'
import { AuthUser, AuthState } from '@/types/auth'

const state = (): AuthState => ({
  authUser: undefined
})

const mutations: MutationTree<AuthState> = {
  setAuthUser (state, authUser?: AuthUser) {
    console.log('[STORE MUTATIONS] - setAuthUser')
    state.authUser = authUser
  }
}

const actions = {}

const getters = {}

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
